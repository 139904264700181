<template>
  <b-container
    fluid
  >
    <b-row class="mb-4">
      <b-col
        cols="12"
      >
        <b-card
          title="Payment Requests"
          sub-title="My Documents and Checks"
          class="shadow-sm"
        >
          <b-row>

            <b-col
              cols="12"
              md="3"
              class="p-2"
            >
              <PaleCard
                label="Not Printed Documents"
                variant="warning"
                :count="approvals.pending_document_printing"
                :link="{ name: 'da.payment-requests.document-printing', params: { da: 'pending' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="p-2"
            >
              <PaleCard
                label="Printed Documents"
                variant="success"
                :count="approvals.processed_document_printing"
                :link="{ name: 'da.payment-requests.document-printing', params: { da: 'histories' }}"
                :loading="fetching"
              />
            </b-col>

            <b-col
              cols="12"
              md="3"
              class="p-2"
            >
              <PaleCard
                label="Not Signed Checks"
                variant="warning"
                :count="approvals.pending_check_signing"
                :link="{ name: 'da.payment-requests.check-signing', params: { da: 'pending' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="p-2"
            >
              <PaleCard
                label="Signed Checks"
                variant="success"
                :count="approvals.processed_check_signing"
                :link="{ name: 'da.payment-requests.check-signing', params: { da: 'histories' }}"
                :loading="fetching"
              />
            </b-col>

            <b-col
              cols="12"
              md="3"
              class="p-2"
            >
              <PaleCard
                label="Waiting for Release"
                variant="warning"
                :count="approvals.pending_releasing"
                :link="{ name: 'da.payment-requests.releasing', params: { da: 'pending' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="p-2"
            >
              <PaleCard
                label="Released"
                variant="success"
                :count="approvals.processed_releasing"
                :link="{ name: 'da.payment-requests.releasing', params: { da: 'histories' }}"
                :loading="fetching"
              />
            </b-col>

          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { DADashboardService } from '@/services'
import PaleCard from '@/components/custom/PaleCard'

export default {
  name: 'DaDashboard',

  middleware: ['auth', 'da'],

  components: {
    PaleCard
  },

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      approvals: {
        pending_document_printing: 0,
        pending_check_signing: 0,
        pending_releasing: 0,
        processed_document_printing: 0,
        processed_check_signing: 0,
        processed_releasing: 0
      }
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {

    async getStatistics () {
      this.fetching = true
      return await DADashboardService.get().then(({ data }) => {
        this.approvals.pending_document_printing = data.pending_document_printing
        this.approvals.pending_check_signing = data.pending_check_signing
        this.approvals.pending_releasing = data.pending_releasing
        this.approvals.processed_document_printing = data.processed_document_printing
        this.approvals.processed_check_signing = data.processed_check_signing
        this.approvals.processed_releasing = data.processed_releasing
        this.fetching = false
      })
    }

  }
}
</script>
